export const GET_ENQUIRIES = "GET_ENQUIRIES";
export const GET_ENQUIRIES_SUCCESS = "GET_ENQUIRIES_SUCCESS";
export const GET_ENQUIRIES_FAIL = "GET_ENQUIRIES_FAIL";

export interface IEnquiryState {
  enquiries: IEnquiryData[];
  fetchingEnquiries?: boolean;
  fetchingEnquiriesError?: Error;
}
export interface IQuoteData {
  id: number;
  spaces_taken: number;
  currency: number;
  start_date: string;
  end_date: string;
  duration: number;
  quote_items: IQuoteItem[];
  currency_symbol: string;
  deposit: string;
  total_price: string;
  grand_total: string;
  total_paid: string;
  total_cleared: string;
  total_due: string;
  balance_due: string;
  deposit_due: string;
  total_price__customer: string;
  grand_total__customer: string;
  total_paid__customer: string;
  total_cleared__customer: string;
  total_due__customer: string;
  balance_due__customer: string;
  deposit_due__customer: string;
  balance_due_date: string;
  customer_payments: ICustomerPayment[];
  online_payment: boolean;
  discounts: IDiscount[];
  promotion_reductions: any[];
  social_trip: number;
  private_trip?: any;
  total_commission?: any;
  is_nightly_pricing: boolean;
  exchange_rate: number;
  payment_currency_symbol: string;
  currency_code: string;
  payment_currency_code: string;
  payment_platform: string;
  is_guaranteed: boolean;
  pax_required: number;
}

export interface IDiscount {
  discount_code__description: string;
  discount_code__code: string;
  amount: string;
  payment_amount: string;
}

export interface IQuoteItem {
  id: number;
  quote: number;
  total_price: number;
  total_price__customer: number;
  description: string;
  quantity: number;
  unit_price: number;
}

export interface ICustomerPayment {
  total_refundable: string;
  id: number;
  payment_platform: number;
  quote: number;
  refunding_payment?: any;
  amount: string;
  payment_amount: string;
  status: string;
  description: string;
  created: Date;
  status_modified: Date;
}

export interface IPayment {
  total_refundable: number;
  id: number;
  payment_platform: number;
  quote: number;
  refunding_payment: number | null;
  amount: number;
  payment_amount: number;
  status: string;
  description: string;
  created: string;
  status_modified: string;
}

export interface IEnquiryData {
  id: number;
  number_of_adults: number;
  quote?: IQuoteData;
  status: {
    name: "Booked" | "Quoted" | "Cancelled" | "Declined" | "Enquired";
  };
  user: {
    full_name: string;
  };
  product: {
    id: number;
    title: string;
  };
}

interface getEnquiriesAction {
  type: typeof GET_ENQUIRIES;
}

interface getEnquiriesSuccessAction {
  type: typeof GET_ENQUIRIES_SUCCESS;
  payload: IEnquiryData[];
}

interface getEnquiriesFailAction {
  type: typeof GET_ENQUIRIES_FAIL;
  payload: Error;
}

export type EnquiriesActionTypes =
  | getEnquiriesAction
  | getEnquiriesSuccessAction
  | getEnquiriesFailAction;
