import axios from "axios";
import { IUserData } from "./types";

function setJWTHeader(token: string) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

class UserService {
  async fetchCurrentUser() {
    const res = await axios.get(
      process.env.REACT_APP_ENDPOINT_CURRENT_USER as string,
      {
        withCredentials: true,
        headers: {
          Authorization: ""
        }
      }
    );

    if (res.data.user !== null) {
      const user = res.data.user as IUserData;
      if (user.jwt) setJWTHeader(user.jwt);
      return user;
    }
  }

  async refreshAuthToken(token: string) {
    const res = await axios.post(
      process.env.REACT_APP_ENDPOINT_TOKEN_REFRESH as string,
      { token },
      {
        headers: {
          Authorization: ""
        }
      }
    );
    const newToken = res.data.token;
    setJWTHeader(newToken);
    return newToken;
  }
}

export default new UserService();
