import { ITripData } from "../../store/trips/types";

const BASE_URL = `${process.env.REACT_APP_MAIN_DOMAIN}`;

export function csvBookingReportURL(tripData: ITripData): string {
  const url = new URL(`/manager/booking-report/download`, BASE_URL);
  const searchParams = new URLSearchParams({
    product: tripData.product.toString(),
    filter_departure_date: "on",
    from_start_date: tripData.start_date,
    to_start_date: tripData.start_date,
  });
  url.search = searchParams.toString();
  return url.toString();
}

export function printableBookingReportURL(tripData: ITripData): string {
  const url = new URL(
    `/reports/bookings/${tripData.product}/${tripData.uuid}/`,
    BASE_URL
  );
  return url.toString();
}

export function csvPassengerReportURL(tripData: ITripData): string {
  const url = new URL(`/passengers/${tripData.uuid}/csv/`, BASE_URL);
  return url.toString();
}
