import axios from "axios";
import { MetabaseReportParams } from "./types";

class MetabaseService {
  async getMetabaseReportSrc(params: MetabaseReportParams) {
    const res = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_METABASE}${params.type}/${params.id}/${params.product}/`
    );
    return res.data.src;
  }
}

export default new MetabaseService();
