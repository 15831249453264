import React from "react";
import { Grid, Typography, IconButton, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";

import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  setMeetingPoint,
  setEmergencyContact,
} from "../../store/trips/actions";

import { nl2br } from "../../nl2br";

interface Props {
  tripId: number;
  label: string;
  fieldName: "emergency_contact" | "meeting_point";
  value?: string;
  isInherited?: boolean;
}

const EditableField = ({
  tripId,
  label,
  fieldName,
  value = "(not set)",
  isInherited,
}: Props) => {
  const [editing, setEditing] = React.useState(false);
  const [newValue, setNewValue] = React.useState(isInherited ? "" : value);
  const dispatch = useDispatch();

  const startEditing = () => {
    setNewValue(isInherited ? "" : value);
    setEditing(true);
  };

  const _setField = (value: string) => {
    const func =
      fieldName === "emergency_contact" ? setEmergencyContact : setMeetingPoint;
    dispatch(func(value, tripId));
  };

  const save = () => {
    _setField(newValue);
    setEditing(false);
  };

  const cancel = () => {
    setNewValue(value);
    setEditing(false);
  };

  const unset = () => {
    _setField("");
    setNewValue("");
  };

  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
      alignItems="center"
      justify="space-between"
    >
      <Grid item xs={12} sm={8}>
        {editing ? (
          <>
            <TextField
              label={label}
              placeholder={isInherited ? value : ""}
              multiline
              rowsMax="20"
              rows="2"
              fullWidth
              variant="outlined"
              value={newValue}
              autoFocus
              onChange={(e) => setNewValue(e.target.value)}
            />
          </>
        ) : (
          <>
            <Typography
              color={isInherited ? "textSecondary" : "secondary"}
              variant="h6"
            >
              {label}
            </Typography>

            <Typography color={isInherited ? "textSecondary" : "inherit"}>
              {nl2br(newValue || value || "-")}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item>
        {editing ? (
          <>
            <IconButton onClick={save} key="saveButton">
              <CheckIcon />
            </IconButton>
            <IconButton onClick={cancel} key="cancelButton">
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton onClick={startEditing} key="editButton">
              <EditIcon />
            </IconButton>
            <IconButton
              disabled={isInherited}
              onClick={() => unset()}
              key="deleteButton"
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default EditableField;
