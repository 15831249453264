import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Box,
  TextField,
  Card,
  CardContent,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { connect, ConnectedProps, useDispatch } from "react-redux";

import { closeMessenger, sendMessage } from "../../store/trips/actions";
import { RootState } from "../../store";
import TripCardContent from "./TripCardContent";

const mapState = ({ trips }: RootState) => ({
  open: trips.messengerIsOpen,
  trip: trips.messageTrip,
  product: trips.messageTripProduct,
  sending: trips.messageIsSending,
  error: trips.messageSendError,
  sent: trips.messageSent,
});

const mapDispatch = {
  handleClose: () => closeMessenger(),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { showTripDetails?: boolean };

const Messenger = ({
  open,
  handleClose,
  sending,
  sent,
  error,
  trip,
  product,
  showTripDetails,
}: Props) => {
  const dispatch = useDispatch();
  const [messageContent, setMessageContent] = React.useState("");
  const canSend = messageContent.length > 10 && !sending;

  const handleSend = () => {
    if (trip) {
      dispatch(sendMessage(messageContent, trip.id));
    }
  };

  const handleCancel = () => {
    if (!sending) {
      setMessageContent("");
      handleClose();
    }
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      {sent ? (
        <>
          <DialogTitle>Message sent!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Your message has been sent on to those enquiries.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              OK
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Message all confirmed bookers</DialogTitle>
          <DialogContent>
            {showTripDetails && trip && product && (
              <Box mb={2}>
                <Card variant="outlined">
                  <CardContent>
                    <TripCardContent product={product} tripData={trip} />
                  </CardContent>
                </Card>
              </Box>
            )}
            {error && (
              <DialogContentText color="error">
                {error.message}, please try again.
              </DialogContentText>
            )}
            <TextField
              id="standard-multiline-flexible"
              label="Your Message"
              placeholder="Enter your message here"
              multiline
              rowsMax="20"
              rows="5"
              fullWidth
              error={Boolean(error)}
              variant="outlined"
              value={messageContent}
              autoFocus
              onChange={(e) => setMessageContent(e.target.value)}
              disabled={sending}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} disabled={sending}>
              Cancel
            </Button>
            <Button onClick={handleSend} color="secondary" disabled={!canSend}>
              {sending ? "Sending..." : "Send"}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default connector(Messenger);
