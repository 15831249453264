import {
  IPeriodsState,
  PeriodsActionTypes,
  IPeriodData,
  SET_PERIODS,
  GET_PERIODS,

} from "./types";

import { deepCopy, upsert } from "../../utils";
import moment from "moment";

const initialState: IPeriodsState = {
  periods: [],
  fetching: false,
};

function removeNullAttributes(periodData: IPeriodData): IPeriodData {
  const newPeriod: { [key: string]: any } = {};
  Object.entries(periodData).forEach(([k, v]) => {
    if (v !== null) {
      newPeriod[k] = v;
    }
  });
  return newPeriod as IPeriodData;
}

function sortPeriods(a: IPeriodData, b: IPeriodData) {
  // sorty by product id, then date
  if (a.product > b.product) {
    return 1;
  } else if (a.product < b.product) {
    return -1;
  }

  const startDateA = moment(a.start_date);
  const startDateB = moment(b.start_date);
  if (startDateA < startDateB) {
    return -1;
  } else if (startDateA > startDateB) {
    return 1;
  }
  return 1
}

function mergePeriods(
  currentPeriods: IPeriodData[],
  newPeriods: IPeriodData[]
): IPeriodData[] {
  return deepCopy(
    (newPeriods
      .map(removeNullAttributes)
      .reduce(upsert, [...currentPeriods]) as IPeriodData[]).sort(sortPeriods)
  );
}


export function periodsReducer(
  state = initialState,
  action: PeriodsActionTypes
): IPeriodsState {
  switch (action.type) {
    case SET_PERIODS:
      return {
        ...state,
        periods: mergePeriods(state.periods, action.payload),
        fetching: false
      };
    case GET_PERIODS:
      return {
        ...state,
        fetching: true
      };
    default:
      return state;
  }
}
