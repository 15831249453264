import React from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";

import { Card, TextField, MenuItem, Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { RootState } from "../../store";
import { getProducts } from "../../store/products/actions";
import { MetabaseReportParams } from "../../store/reports/types";
import { getMetabaseReportSrc } from "../../store/reports/actions";

const mapState = ({ reports, products }: RootState) => ({
  products: products.products,
  ...reports,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Reports = ({
  products,
  metabaseReportSrc,
  gettingMetabaseReportSrc,
  metabaseReportParams,
  metabaseOptions,
}: PropsFromRedux) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  React.useEffect(() => {
    const defaultProductID = Object.keys(products)[0];
    if (defaultProductID === undefined) return;

    const defaultID = Object.keys(metabaseOptions)[0];
    const params: MetabaseReportParams = {
      product: defaultProductID,
      id: defaultID,
      type: metabaseOptions[defaultID].type,
    };

    dispatch(getMetabaseReportSrc(params));
  }, [dispatch, metabaseOptions, products]);

  React.useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const productOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const params: MetabaseReportParams = {
      product: event.target.value,
      id: metabaseReportParams.id,
      type: metabaseReportParams.type,
    };

    dispatch(getMetabaseReportSrc(params));
  };

  const viewOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const params: MetabaseReportParams = {
      product: metabaseReportParams.product,
      id: event.target.value,
      type: metabaseOptions[event.target.value].type,
    };

    dispatch(getMetabaseReportSrc(params));
  };

  // Render products into menu items.
  const productMenuItems = Object.keys(products).map((productID) => (
    <MenuItem key={productID} id={productID} value={productID}>
      <strong>{productID}</strong>
      &nbsp;{products[productID].name}
    </MenuItem>
  ));

  // Render view options into menu items.
  const viewMenuItems = Object.keys(metabaseOptions).map((metabaseID) => (
    <MenuItem key={metabaseID} id={metabaseID} value={metabaseID}>
      {metabaseOptions[metabaseID].name}
    </MenuItem>
  ));

  return (
    <Card>
      <Box p={1}>
        {metabaseReportParams.product && (
          <Box m={1} display={isXs ? "block" : "inline-block"}>
            <TextField
              id="productSelect"
              label="Product"
              onChange={productOnChange}
              value={metabaseReportParams.product}
              select
              fullWidth={isXs}
            >
              {productMenuItems}
            </TextField>
          </Box>
        )}
        {metabaseReportParams.id && (
          <Box m={1} display={isXs ? "block" : "inline-block"}>
            <TextField
              id="viewSelect"
              label="View"
              onChange={viewOnChange}
              value={metabaseReportParams.id}
              select
              fullWidth={isXs}
            >
              {viewMenuItems}
            </TextField>
          </Box>
        )}
      </Box>
      {gettingMetabaseReportSrc ? (
        <Box p={2}>
          <Typography>Loading...</Typography>
        </Box>
      ) : (
        <iframe
          title="Metabase Embed"
          src={metabaseReportSrc}
          frameBorder="0"
          width="100%"
          style={{ height: "calc(100vh - 260px)" }}
        />
      )}
    </Card>
  );
};

export default connector(Reports);
