import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import { IProductLookup } from "../../store/products/types";
import { ITripData } from "../../store/trips/types";
import Messenger from "./Messenger";
import React from "react";
import { RootState } from "../../store";
import { Link as RouterLink } from "@reach/router";
import TripCard from "./TripCard";
import { getProducts } from "../../store/products/actions";
import { getTrips } from "../../store/trips/actions";
import moment from "moment";

const renderTrips = (
  trips: ITripData[],
  products: IProductLookup,
  offset: number,
  pageSize: number
) => {
  let currentMonthYear = "";
  return [...trips.slice(offset, offset + pageSize)].map((tripData) => {
    const thisMonthYear = moment(tripData.start_date).format("MMMM YYYY");
    const header =
      thisMonthYear !== currentMonthYear ? (
        <Grid item xs={12}>
          <Box pt={4}>
            <Typography variant="h4">{thisMonthYear}</Typography>
          </Box>
        </Grid>
      ) : null;
    currentMonthYear = thisMonthYear;
    return (
      <React.Fragment key={tripData.uuid}>
        {header}
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <TripCard
            tripData={tripData}
            product={products[tripData.product]}
            linkToDetail
          />
        </Grid>
      </React.Fragment>
    );
  });
};

const pagingProps = (currentPage: number, maxOffset: number, pageSize = 20) => {
  const offset = Math.max(0, pageSize * (currentPage - 1));
  const hasNext = maxOffset > offset + pageSize;
  const hasPrev = offset > 0;
  return {
    offset,
    hasNext,
    hasPrev,
    pageSize,
  };
};

const mapState = ({ trips, products }: RootState) => ({
  trips: trips.trips,
  fetchingTrips: trips.fetchingTrips,
  products: products.products,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & { page?: number };

const Trips = ({ fetchingTrips, trips, products, page = 1 }: Props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getTrips());
    dispatch(getProducts());
  }, [dispatch]);

  const { offset, pageSize, hasNext, hasPrev } = pagingProps(
    page,
    trips.length
  );

  return (
    <>
      <Messenger showTripDetails />
      <Typography variant="h3">Trips</Typography>
      <Typography variant="subtitle1" gutterBottom>
        Your currently running and upcoming social trips are listed below.
        Download or print booking reports, or send a message to all current
        bookers.
      </Typography>

      <Grid container spacing={2}>
        {renderTrips(trips, products, offset, pageSize)}
        <Grid item container xs={12} spacing={1}>
          {(hasPrev || hasNext) && (
            <>
              <Grid item>
                <Button
                  startIcon={<ArrowBack />}
                  component={RouterLink}
                  disabled={!hasPrev}
                  to={`/trips/page/${page - 1}`}
                >
                  Prev
                </Button>
              </Grid>
              <Grid item>
                <Button
                  endIcon={<ArrowForward />}
                  component={RouterLink}
                  disabled={!hasNext}
                  to={`/trips/page/${page + 1}`}
                >
                  Next
                </Button>
              </Grid>
            </>
          )}
          {fetchingTrips && (
            <Grid item>
              <Button component="div" disabled>
                Loading...
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default connector(Trips);
