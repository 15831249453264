import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    background: red[500],
    color: "white",
  },
}));

export const ErrorChip = ({ ...props }) => {
  const classes = useStyles();
  return <Chip {...props} className={classes.root} />;
};
