import axios from "axios";

class TripsService {
  async fetchTrips(pageSize?: number) {
    const searchParams = new URLSearchParams([
      ["in_present_or_future", "1"],
      ["page_size", pageSize ? `${pageSize}` : ""]
    ]);
    const res = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_SOCIAL_TRIPS}?${searchParams}`
    );
    return res.data.results;
  }

  async fetchTrip(tripId: number) {
    const res = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_SOCIAL_TRIPS}${tripId}/`
    );
    return res.data;
  }

  async sendMessage(message: string, tripId: number) {
    const res = await axios.put(
      `${process.env.REACT_APP_ENDPOINT_SOCIAL_TRIPS}${tripId}/comment/`,
      {
        comment_body: message
      }
    );
    return res.data.results;
  }

  async setEmergencyContact(value: string, tripId: number) {
    const payload = value ? { emergency_contact: value } : {};
    const res = await axios.put(
      `${process.env.REACT_APP_ENDPOINT_SOCIAL_TRIPS}${tripId}/emergency-contact/`,
      payload
    );
    return res.data;
  }

  async setMeetingPoint(value: string, tripId: number) {
    const payload = value ? { meeting_point: value } : {};
    const res = await axios.put(
      `${process.env.REACT_APP_ENDPOINT_SOCIAL_TRIPS}${tripId}/meeting-point/`,
      payload
    );
    return res.data;
  }
}

export default new TripsService();
