import { CurrencyCode } from "../types";

export interface IProductData {
  id: number;
  name: string;
  currency_code?: CurrencyCode;
  color?: string;
}

export interface IProductLookup {
  [key: string]: IProductData;
}

export interface ProductsState {
  products: IProductLookup;
  fetchingProducts: boolean;
}

export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_PRODUCTS = "SET_PRODUCTS";

interface setProductsAction {
  type: typeof SET_PRODUCTS;
  payload: IProductData[];
}
interface getProductsAction {
  type: typeof GET_PRODUCTS;
}

export type ProductsActionTypes = getProductsAction | setProductsAction;
