import { AppThunk } from "../types";
import Service from "./service";
import { GET_PERIODS, SET_PERIODS, IPeriodData, PeriodsActionTypes } from "./types";

export function setPeriods(periods: IPeriodData[]): PeriodsActionTypes {
  return {
    type: SET_PERIODS,
    payload: periods
  };
}

export const getPeriods = (pageSize?: number): AppThunk => async dispatch => {
  dispatch({ type: GET_PERIODS });
  const asyncResp = await Service.fetchPeriods(pageSize);
  dispatch(setPeriods(asyncResp));
};
