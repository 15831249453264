import moment from "moment";

export function deepCopy(instance: any) {
  return JSON.parse(JSON.stringify(instance));
}

interface IObjWithId {
  id: string | number;
}
export function upsert(arr: IObjWithId[], obj: IObjWithId): IObjWithId[] {
  const foundIdx = arr.findIndex((el) => el.id === obj.id);
  if (foundIdx >= 0) {
    arr[foundIdx] = obj;
  } else {
    arr.push(obj);
  }
  return arr;
}

export const fullDateFmt = "MMMM D, YYYY";

export const formatDateTime = (dateString: string): string =>
  moment(dateString).format("MMMM DD, YYYY @ HH:mm");

export const formatDate = (dateString: string) =>
  moment(dateString).format(fullDateFmt);

export const formatDateRange = (startDateStr: string, endDateStr: string) => {
  const startDate = moment(startDateStr);
  const endDate = moment(endDateStr);

  if (startDateStr === endDateStr) {
    return startDate.format(fullDateFmt);
  }

  const startDateFmt =
    startDate.year() === endDate.year() ? "MMMM D" : fullDateFmt;
  return `${startDate.format(startDateFmt)}–${endDate.format(fullDateFmt)}`;
};

export const formatMoney = (amt: string | number, currencyCode: string) =>
  new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currencyCode,
  }).format(parseFloat(`${amt}`));
