import { Grid } from "@material-ui/core";
import Latest from "../Enquiries/Latest";
import React from "react";
import Upcoming from "../Trips/Upcoming";

const Home = () => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6} md={4}>
      <Upcoming />
    </Grid>
    <Grid item xs={12} sm={6} md={8}>
      <Latest />
    </Grid>
  </Grid>
);

export default Home;
