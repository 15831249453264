import axios from "axios";

class EnquiryService {
  async fetchEnquiries(tripId?: number | null, pageSize = 50) {
    const searchParams = new URLSearchParams([
      ["extended", "detail"],
      ["exclude_status", "Trashed"],
      ["page_size", pageSize.toString()]
    ]);
    if (tripId) searchParams.append("social_trip", `${tripId}`);
    const endpoint = `${process.env.REACT_APP_ENDPOINT_ENQUIRIES}`;
    const res = await axios.get(`${endpoint}?${searchParams}`);
    return res.data.results;
  }
  async fetchEnquiry(enquiryId: number) {
    const searchParams = new URLSearchParams([["extended", "detail"]]);
    const endpoint = `${process.env.REACT_APP_ENDPOINT_ENQUIRIES}`;
    const res = await axios.get(`${endpoint}${enquiryId}/?${searchParams}`);
    return res.data;
  }
}

export default new EnquiryService();
