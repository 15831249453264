import {
  TripsState,
  TripsActionTypes,
  ITripData,
  ISetMeetingPointAction,
  ISetEmergencyContactAction,
  OPEN_FILTERS,
  CLOSE_FILTERS,
  SET_GLOBAL_FILTERS,
  SET_TRIPS,
  GET_TRIPS,
  OPEN_MESSENGER,
  CLOSE_MESSENGER,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAIL,
  SET_EMERGENCY_CONTACT,
  SET_EMERGENCY_CONTACT_SUCCESS,
  SET_MEETING_POINT,
  SET_MEETING_POINT_SUCCESS
} from "./types";

import { deepCopy, upsert } from "../../utils";
import moment from "moment";

const initialState: TripsState = {
  trips: [],
  fetchingTrips: false,
  filterDialogOpen: false,
  messengerIsOpen: false,
  messageIsSending: false,
  globalFilters: [
    {
      label: "Show future trips only",
      value: "future_only",
      on: true
    },
    {
      label: "Show Private Trips",
      value: "show_private_trips",
      on: true
    },
    {
      label: "Show Social Trips",
      value: "show_social_trips",
      on: true
    }
  ]
};

function removeNullAttributes(trip: ITripData): ITripData {
  const newTrip: { [key: string]: any } = {};
  Object.entries(trip).forEach(([k, v]) => {
    if (v !== null) {
      newTrip[k] = v;
    }
  });
  return newTrip as ITripData;
}

function sortTrips(a: ITripData, b: ITripData) {
  // sorty by start date, then id
  const startDateA = moment(a.start_date);
  const startDateB = moment(b.start_date);
  if (startDateA < startDateB) {
    return -1;
  } else if (startDateA > startDateB) {
    return 1;
  }
  return a.id < b.id ? -1 : 1;
}

function mergeTrips(
  currentTrips: ITripData[],
  newTrips: ITripData[]
): ITripData[] {
  return deepCopy(
    (newTrips
      .map(removeNullAttributes)
      .reduce(upsert, [...currentTrips]) as ITripData[]).sort(sortTrips)
  );
}

function findTrip(trips: ITripData[], tripId: number): ITripData | undefined {
  return trips.find(trip => trip.id === tripId);
}

function handleSetEmergencyContactAction(
  action: ISetEmergencyContactAction,
  previousState: TripsState
): TripsState {
  const { tripId, value } = action.payload;
  const trip = findTrip(previousState.trips, tripId);
  if (trip) {
    trip.emergency_contact = value;
    return {
      ...previousState,
      trips: mergeTrips(previousState.trips, [trip])
    };
  }
  return previousState;
}

function handleSetMeetingPointAction(
  action: ISetMeetingPointAction,
  previousState: TripsState
): TripsState {
  const { tripId, value } = action.payload;
  const trip = findTrip(previousState.trips, tripId);
  if (trip) {
    trip.meeting_point = value;
    return {
      ...previousState,
      trips: mergeTrips(previousState.trips, [trip])
    };
  }
  return previousState;
}

export function tripsReducer(
  state = initialState,
  action: TripsActionTypes
): TripsState {
  switch (action.type) {
    case OPEN_FILTERS:
      return {
        ...state,
        filterDialogOpen: true
      };
    case CLOSE_FILTERS:
      return {
        ...state,
        filterDialogOpen: false
      };
    case OPEN_MESSENGER:
      return {
        ...state,
        messageTrip: action.payload.trip,
        messageTripProduct: action.payload.product,
        messengerIsOpen: true,
        messageSendError: undefined,
        messageSent: undefined,
        messageIsSending: false
      };
    case CLOSE_MESSENGER:
      return {
        ...state,
        messengerIsOpen: false
      };
    case SET_EMERGENCY_CONTACT:
      return handleSetEmergencyContactAction(action, state);

    case SET_EMERGENCY_CONTACT_SUCCESS:
      return {
        ...state,
        trips: mergeTrips(state.trips, [action.payload])
      };

    case SET_MEETING_POINT:
      return handleSetMeetingPointAction(action, state);

    case SET_MEETING_POINT_SUCCESS:
      return {
        ...state,
        trips: mergeTrips(state.trips, [action.payload])
      };

    case SEND_MESSAGE:
      return {
        ...state,
        messageIsSending: true
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messageIsSending: false,
        messageSent: true
      };
    case SEND_MESSAGE_FAIL:
      return {
        ...state,
        messageIsSending: false,
        messageSendError: action.payload
      };
    case SET_GLOBAL_FILTERS:
      return {
        ...state,
        globalFilters: deepCopy(action.payload)
      };
    case SET_TRIPS:
      return {
        ...state,
        trips: mergeTrips(state.trips, action.payload),
        fetchingTrips: false
      };
    case GET_TRIPS:
      return {
        ...state,
        fetchingTrips: true
      };
    default:
      return state;
  }
}
