import React from "react";

const newline = "\n";

export function nl2br(text: string) {
  let lines = text.split(newline);
  return lines.map((line, i) => (
    <span key={i}>
      {line}
      <br />
    </span>
  ));
}
