import { AppThunk } from "../types";
import MetabaseService from "./service";

import {
  GET_METABASE_REPORT_SRC,
  SET_METABASE_REPORT_SRC,
  SET_METABASE_REPORT_PARAMS,
  MetabaseReportParams,
  ReportsActionTypes
} from "./types";

export function setMetabaseReportParams(
  params: MetabaseReportParams
): ReportsActionTypes {
  return {
    type: SET_METABASE_REPORT_PARAMS,
    payload: params
  };
}

export function setMetabaseReportSrc(src: string): ReportsActionTypes {
  return {
    type: SET_METABASE_REPORT_SRC,
    payload: src
  };
}

export const getMetabaseReportSrc = (
  params: MetabaseReportParams
): AppThunk => async dispatch => {
  dispatch({ type: GET_METABASE_REPORT_SRC });
  dispatch(setMetabaseReportParams(params));
  const asyncResp = await MetabaseService.getMetabaseReportSrc(params);
  dispatch(setMetabaseReportSrc(asyncResp));
};
