import { AppThunk } from "../types";
import ProductsService from "./service";

import {
  GET_PRODUCTS,
  SET_PRODUCTS,
  IProductData,
  ProductsActionTypes
} from "./types";

export function setProducts(products: IProductData[]): ProductsActionTypes {
  return {
    type: SET_PRODUCTS,
    payload: products
  };
}

export const getProducts = (): AppThunk => async dispatch => {
  dispatch({ type: GET_PRODUCTS });
  const asyncResp = await ProductsService.fetchProducts();
  dispatch(setProducts(asyncResp));
};
