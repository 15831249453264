import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme/theme";
import "./App.css";
import Layout from "./Layout";
import { CssBaseline } from "@material-ui/core";
import { LocationProvider } from "@reach/router";

const App = () => {
  return (
    <Provider store={store}>
      <CssBaseline />
      <LocationProvider>
        <ThemeProvider theme={theme}>
          <Layout />
        </ThemeProvider>
      </LocationProvider>
    </Provider>
  );
};

export default App;
