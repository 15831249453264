export type GroupSize = {
  min_pax: number;
  max_pax: number;
  percentage_reduction: number;
  adult_price: string;
  child_price?: string;
}

export interface IPeriodData {
  id: number;
  product: number;
  start_date: string;
  end_date: string;
  base_adult_price: string;
  base_child_price?: string;
  duration: number;
  description: string,
  group_sizes: GroupSize[]
}

export interface IPeriodsState {
  periods: IPeriodData[];
  fetching: boolean;
}

export const SET_PERIODS = "SET_PERIODS";
export const GET_PERIODS = "GET_PERIODS";

export interface IGetPeriodsAction {
  type: typeof GET_PERIODS;
}
export interface ISetPeriodsAction {
  type: typeof SET_PERIODS;
  payload: IPeriodData[];
}

export type PeriodsActionTypes =
  | IGetPeriodsAction
  | ISetPeriodsAction