export interface MetabaseReportParams {
  product: string | undefined;
  id: string | undefined;
  type: string | undefined;
}

export interface MetabaseOption {
  name: string;
  type: string;
}

export interface MetabaseOptions {
  [key: string]: MetabaseOption;
}

export interface ReportsState {
  gettingMetabaseReportSrc: boolean;
  metabaseReportSrc: string | undefined;
  metabaseReportParams: MetabaseReportParams;
  metabaseOptions: MetabaseOptions;
}

export const GET_METABASE_REPORT_SRC = "GET_METABASE_REPORT_SRC";
export const SET_METABASE_REPORT_SRC = "SET_METABASE_REPORT_SRC";
export const SET_METABASE_REPORT_PARAMS = "SET_METABASE_REPORT_PARAMS";

interface getMetabaseReportSrc {
  type: typeof GET_METABASE_REPORT_SRC;
  payload: boolean;
}
interface setMetabaseReportSrc {
  type: typeof SET_METABASE_REPORT_SRC;
  payload: string;
}
interface setMetabaseReportParams {
  type: typeof SET_METABASE_REPORT_PARAMS;
  payload: MetabaseReportParams;
}

export type ReportsActionTypes =
  | getMetabaseReportSrc
  | setMetabaseReportSrc
  | setMetabaseReportParams;
