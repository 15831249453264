import { applyMiddleware, combineReducers, compose, createStore } from "redux";

import { enquiriesReducer } from "./enquiries/reducers";
import { periodsReducer } from "./privategrouppricingperiods/reducers";
import { productsReducer } from "./products/reducers";
import { reportsReducer } from "./reports/reducers";
import thunk from "redux-thunk";
import { tripsReducer } from "./trips/reducers";
import { userReducer } from "./user/reducers";

const rootReducer = combineReducers({
  trips: tripsReducer,
  privateGroupPricingPeriods: periodsReducer,
  products: productsReducer,
  user: userReducer,
  enquiries: enquiriesReducer,
  reports: reportsReducer
});

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof rootReducer>;
