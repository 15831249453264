import React from "react";
import { Box, Grid, Typography, Button, Link } from "@material-ui/core";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Link as RouterLink } from "@reach/router";

import { getProducts } from "../../store/products/actions";
import { RootState } from "../../store";

import { IProductLookup, IProductData } from "../../store/products/types";
import { getPeriods } from "../../store/privategrouppricingperiods/actions";
import PeriodCard from "./PeriodCard";
import { IPeriodData } from "../../store/privategrouppricingperiods/types";

const renderPeriods = (
  periods: IPeriodData[],
  products: IProductLookup,
  offset: number,
  pageSize: number
) => {
  let currentProduct: IProductData;
  let defaultProduct: IProductData = {
    id: 0,
    name: "Unknown Product",
    currency_code: "GBP",
  };
  return [...periods.slice(offset, offset + pageSize)].map((periodData) => {
    const thisProduct = products[periodData.product] || defaultProduct;
    const header =
      thisProduct !== currentProduct ? (
        <Grid item xs={12}>
          <Box pt={4}>
            <Typography variant="caption">#{thisProduct.id}</Typography>
            <Typography variant="h3">{thisProduct.name}</Typography>
          </Box>
        </Grid>
      ) : null;
    currentProduct = thisProduct;
    return (
      <React.Fragment key={periodData.id}>
        {header}
        <Grid item sm={12} md={6} lg={4}>
          <PeriodCard
            periodData={periodData}
            currencyCode={thisProduct.currency_code || "GBP"}
          />
        </Grid>
      </React.Fragment>
    );
  });
};

const pagingProps = (currentPage: number, maxOffset: number, pageSize = 20) => {
  const offset = Math.max(0, pageSize * (currentPage - 1));
  const hasNext = maxOffset > offset + pageSize;
  const hasPrev = offset > 0;
  return {
    offset,
    hasNext,
    hasPrev,
    pageSize,
  };
};

const mapState = ({ privateGroupPricingPeriods, products }: RootState) => ({
  periods: privateGroupPricingPeriods.periods,
  fetchingPeriods: privateGroupPricingPeriods.fetching,
  products: products.products,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & { page?: number };

const Periods = ({ fetchingPeriods, periods, products, page = 1 }: Props) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getPeriods());
    dispatch(getProducts());
  }, [dispatch]);

  const { offset, pageSize, hasNext, hasPrev } = pagingProps(
    page,
    periods.length
  );

  return (
    <>
      <Typography variant="h2">Private Group Pricing Periods</Typography>
      <Typography paragraph>
        Your private group pricing periods are set out below. Please{" "}
        <Link href="mailto:hello@mbad.co?subject=Private+Group+Pricing">
          get in touch
        </Link>{" "}
        if you would like to change anything.
      </Typography>

      <Grid container spacing={2}>
        {renderPeriods(periods, products, offset, pageSize)}
        <Grid item container xs={12} spacing={1}>
          {(hasPrev || hasNext) && (
            <>
              <Grid item>
                <Button
                  component={RouterLink}
                  disabled={!hasPrev}
                  to={`/private-group-pricing-periods/page/${page - 1}`}
                >
                  Prev
                </Button>
              </Grid>
              <Grid item>
                <Button
                  component={RouterLink}
                  disabled={!hasNext}
                  to={`/private-group-pricing-periods/page/${page + 1}`}
                >
                  Next
                </Button>
              </Grid>
            </>
          )}
          {fetchingPeriods && (
            <Grid item>
              <Button component="div" disabled>
                Loading...
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default connector(Periods);
