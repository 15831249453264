import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import ListComponent from "./Latest";
import DetailComponent, { Props as DetailComponentProps } from "./Detail";
import { Box, Link, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const List: React.SFC<RouteComponentProps> = () => <ListComponent />;

type Props = RouteComponentProps & DetailComponentProps;
const Detail: React.SFC<RouteComponentProps> = (props) => {
  const detailProps = props as DetailComponentProps;
  return <DetailComponent enquiryIdStr={detailProps.enquiryIdStr} />;
};

const Trips = () => {
  return (
    <>
      <Box mb={2}>
        <Alert severity="info">
          <Typography>
            Whilst we're working on the new manager, you can still{" "}
            <Link
              href="https://www.muchbetteradventures.com/manager/inbox/"
              color="secondary"
            >
              access your enquiries inbox here
            </Link>
            .
          </Typography>
        </Alert>
      </Box>
      <Router>
        <List path="/" />
        <Detail path="/:enquiryIdStr" />
      </Router>
    </>
  );
};

export default Trips;
