import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Link as RouterLink } from "@reach/router";

import { openFilters, getTrips } from "../../store/trips/actions";
import { getProducts } from "../../store/products/actions";
import { RootState } from "../../store";

import TripCard from "./TripCard";
import Messenger from "./Messenger";

const mapState = ({ trips, products }: RootState) => ({
  trips: trips.trips,
  fetchingTrips: trips.fetchingTrips,
  products: products.products,
});

const mapDispatch = {
  openFilters: () => openFilters(),
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { pageSize?: number };

const Upcoming = ({
  openFilters,
  fetchingTrips,
  trips,
  products,
  pageSize = 3,
}: Props) => {
  const getProduct = (productId: number) => products[productId.toString()];

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getTrips(pageSize));
    dispatch(getProducts());
  }, [dispatch, pageSize]);

  return (
    <>
      <Messenger showTripDetails />
      <Typography variant="h4" gutterBottom noWrap>
        Upcoming Trips
      </Typography>

      {fetchingTrips && trips.length === 0 && (
        <Typography color="textSecondary">Loading...</Typography>
      )}
      <Grid container spacing={2}>
        {trips.slice(0, pageSize).map((tripData) => {
          return (
            <React.Fragment key={tripData.uuid}>
              <Grid item xs={12}>
                <TripCard
                  tripData={tripData}
                  product={getProduct(tripData.product)}
                  linkToDetail
                />
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid item xs={12}>
          <Button
            component={RouterLink}
            to="/trips"
            variant="outlined"
            endIcon={<ArrowForwardIcon />}
          >
            All Upcoming Trips
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default connector(Upcoming);
