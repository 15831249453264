import { Box, Chip, Divider, Grid } from "@material-ui/core";
import { formatDate, formatDateRange, fullDateFmt } from "../../utils";

import { ErrorChip } from "../shared/ErrorChip";
import { IProductData } from "../../store/products/types";
import { ITripData } from "../../store/trips/types";
import Pax from "./Pax";
import React from "react";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

type Props = { tripData: ITripData; product?: IProductData };

const TripCardContent = ({
  tripData: {
    booked_pax,
    cancelled_at,
    end_date,
    guaranteed_at,
    is_cancelled,
    is_exclusive,
    is_hidden,
    is_private,
    max_pax,
    pax_required,
    spaces_left,
    start_date,
    confirmation_date,
    min_pax,
  },
  product = { id: 0, name: "", color: "" },
}: Props) => {
  console.log();
  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs>
          <Typography variant="h6">
            {formatDateRange(start_date, end_date)}
          </Typography>
        </Grid>
        <Grid item>
          {is_cancelled && cancelled_at ? (
            <ErrorChip label="Cancelled" />
          ) : (
            <>
              {guaranteed_at && moment(start_date).isBefore(new Date()) && (
                <Chip color="default" label="In progress" />
              )}
              {guaranteed_at &&
                moment(start_date).isSameOrAfter(new Date()) && (
                  <Chip color="primary" label="Confirmed" />
                )}
            </>
          )}
        </Grid>
      </Grid>
      <Typography color="textSecondary" noWrap title={product.name}>
        {product.name}
      </Typography>
      <Box mb={2}>
        <Box paddingY={1}>
          <Pax
            minPax={min_pax}
            maxPax={max_pax}
            bookedPax={booked_pax}
            noWrap
          />
        </Box>
        <Typography>
          {booked_pax} booked &middot; {min_pax} min &middot; {spaces_left}{" "}
          available &middot; {max_pax} total
        </Typography>
      </Box>
      {is_cancelled && cancelled_at ? (
        <>
          <Typography color="textSecondary" variant="body2">
            Cancelled on {formatDate(cancelled_at)}
          </Typography>
        </>
      ) : guaranteed_at ? (
        <>
          <Typography color="textSecondary" variant="body2">
            Confirmed on {formatDate(guaranteed_at)}
          </Typography>
        </>
      ) : (
        <>
          <Typography color="textSecondary" variant="body2" noWrap>
            <>
              {pax_required > 0
                ? `${pax_required} pax required. `
                : "Minimum group size met. "}
              Confirming by {moment(confirmation_date).format(fullDateFmt)}
            </>
          </Typography>
        </>
      )}
      <Box marginY={2}>
        <Divider />
      </Box>

      <Grid container spacing={1}>
        <Grid item>
          <Chip label={`#${product.id}`} variant="outlined" />
        </Grid>
        {!is_exclusive && (
          <Grid item>
            <Chip label="Non-Exclusive" variant="outlined" />
          </Grid>
        )}
        {!is_private && is_hidden && (
          <Grid item>
            <Chip label="Secret" variant="outlined" />
          </Grid>
        )}
        {is_private && (
          <Grid item>
            <Chip label="Private" variant="outlined" />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default TripCardContent;
