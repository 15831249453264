import axios from "axios";

class ProductsService {
  async fetchProducts() {
    const res = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_PRODUCTS}?is_on_sale=1`
    );
    return res.data.results;
  }
}

export default new ProductsService();
