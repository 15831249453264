import axios from "axios";

class Service {
  async fetchPeriods(pageSize?: number, productId?: number) {
    const searchParams = new URLSearchParams([
      ["in_future", "1"],
      ["page_size", pageSize ? `${pageSize}` : ""],
      ["product", productId ? `${productId}` : ""]
    ]);
    const res = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_PRIVATE_GROUP_PRICING_PERIODS}?${searchParams}`
    );
    return res.data.results;
  }

  async fetchPeriod(id: number) {
    const res = await axios.get(
      `${process.env.REACT_APP_ENDPOINT_PRIVATE_GROUP_PRICING_PERIODS}${id}/`
    );
    return res.data;
  }

}

export default new Service();
