import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { TypographyStyleOptions } from "@material-ui/core/styles/createTypography";

const fontFamily = [
  "Rubik",
  '"Helvetica Neue"',
  "Helvetica",
  "Arial",
  "sans-serif",
].join(",");

const textBlack = "#2A2D2C";

const headersCommon: TypographyStyleOptions = {
  textTransform: "uppercase",
  fontWeight: 700,
};

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#98BE42",
    },
    secondary: {
      main: "#428bca",
    },
  },
  typography: {
    fontFamily,
    h1: {
      fontWeight: 900,
      textTransform: "uppercase",
    },
    h2: headersCommon,
    h3: headersCommon,
    h4: headersCommon,
    h5: headersCommon,
    h6: headersCommon,
  },
  overrides: {
    MuiCollapse: {
      entered: {
        height: "auto",
        overflow: "visible",
      },
    },
    MuiFormLabel: {
      root: {
        margin: 0,
      },
    },
    MuiInputLabel: {
      root: {
        "z-index": 999,
      },
    },
    MuiChip: {
      colorPrimary: {
        color: "white",
      },
    },
    MuiButton: {
      root: {},
      contained: {
        boxShadow: "none",
      },
      outlined: {},
      containedPrimary: {
        color: "white",
      },
    },
  },
});
theme = responsiveFontSizes(theme);
export default theme;
export { textBlack, fontFamily };
