import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { RouteComponentProps, Router, useLocation } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";

import EnquiriesComponent from "./components/Enquiries";
import HomeComponent from "./components/Home";
import ManagerAppBar from "./components/ManagerAppBar";
import PrivateGroupPricingPeriodsComponent from "./components/PrivateGroupPricingPeriods";
import React from "react";
import ReportsComponent from "./components/Reports";
import { RootState } from "./store";
import TripsComponent from "./components/Trips";
import { getUser } from "./store/user/actions";

const Trips: React.SFC<RouteComponentProps> = () => <TripsComponent />;
const PrivateGroupPricingPeriods: React.SFC<RouteComponentProps> = () => (
  <PrivateGroupPricingPeriodsComponent />
);
const Enquiries: React.SFC<RouteComponentProps> = () => <EnquiriesComponent />;
const Reports: React.SFC<RouteComponentProps> = () => <ReportsComponent />;
const Home: React.SFC<RouteComponentProps> = () => <HomeComponent />;

interface InitializingProps {
  message: string;
  isError?: boolean;
}
const Initializing = ({ message, isError }: InitializingProps) => (
  <Box maxWidth={700} p={4}>
    <img
      alt="Much Better Adventures"
      src="https://d9742cxh932vm.cloudfront.net/2cba190709f9f9da32ff206998669fd3/img/muchbetteradventures.svg"
      style={{ height: 48, marginBottom: 32 }}
    />

    {isError ? (
      <Dialog open>
        <DialogTitle>Not Logged In!</DialogTitle>
        <DialogContent>
          <DialogContentText>{message} You have two choices:</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button href="/">Reload</Button>
          <Button
            href={`${process.env.REACT_APP_MAIN_DOMAIN}/accounts/login/?next=${document.location.href}`}
          >
            Reauthenticate
          </Button>
        </DialogActions>
      </Dialog>
    ) : (
      <Typography variant="h6" paragraph>
        {message}
      </Typography>
    )}
  </Box>
);

const Layout = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user, fetchingUser, fetchingUserError } = useSelector(
    (state: RootState) => state.user
  );

  if (!user && fetchingUser) {
    return <Initializing message="Initializing..." />;
  }

  if (fetchingUserError) {
    return (
      <Initializing
        message={`Fetching user failed: ${fetchingUserError.message}. Please try again`}
        isError
      />
    );
  }

  if (!user || !(user.is_host || user.is_staff)) {
    return (
      <Initializing
        message="You don't appear to have access to Manager!"
        isError
      />
    );
  }

  return (
    <>
      <ManagerAppBar />
      <Container maxWidth="xl">
        <Box mt={10}>
          <Router>
            <Home path="/" />
            <Trips path="/trips/*" />
            <PrivateGroupPricingPeriods path="/private-group-pricing-periods/*" />
            <Enquiries path="/enquiries/*" />
            <Reports path="/reports/*" />
          </Router>
        </Box>
      </Container>
    </>
  );
};

export default Layout;
