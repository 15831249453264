import React from "react";
import {
  Typography,
  Card,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
} from "@material-ui/core";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { getEnquiries } from "../../store/enquiries/actions";
import Enquiry from "./EnquiryListItem";

const Latest = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getEnquiries(null, 10));
  }, [dispatch]);

  const enquiries = useSelector((state: RootState) =>
    state.enquiries.enquiries.slice(0, 10)
  );
  const fetchingEnquiries = useSelector(
    (state: RootState) => state.enquiries.fetchingEnquiries
  );

  return (
    <>
      <Typography variant="h4" gutterBottom noWrap>
        Latest Enquiries
      </Typography>
      <Box mb={2}>
        <Card variant="outlined">
          <List>
            {enquiries.length > 0 ? (
              enquiries.map((enquiryData) => (
                <React.Fragment key={enquiryData.id}>
                  <Enquiry enquiryData={enquiryData} />
                </React.Fragment>
              ))
            ) : (
              <ListItem>
                <ListItemText
                  primary={
                    fetchingEnquiries ? "Loading..." : "No enquiries found"
                  }
                />
              </ListItem>
            )}
          </List>
        </Card>
      </Box>
      <Button
        href={`${process.env.REACT_APP_MAIN_DOMAIN}/manager/inbox/`}
        variant="outlined"
        endIcon={<ArrowForwardIcon />}
      >
        All Enquiries
      </Button>
    </>
  );
};

export default Latest;
