export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const REFRESH_AUTH_TOKEN = "REFRESH_AUTH_TOKEN";
export const REFRESH_AUTH_TOKEN_SUCCESS = "REFRESH_AUTH_TOKEN_SUCCESS";
export const REFRESH_AUTH_TOKEN_FAIL = "REFRESH_AUTH_TOKEN_FAIL";

export interface IUserState {
  user?: IUserData;
  fetchingUser: boolean;
  fetchingUserError?: Error;
}
export interface IUserData {
  email: string;
  first_name: string;
  last_name: string;
  id: number;
  is_host: boolean;
  is_staff: boolean;
  intercom_user_hash?: string;
  userprofile: {
    [key: string]: any;
  };
  jwt?: string;
}

interface getUserAction {
  type: typeof GET_USER;
}

interface getUserSuccessAction {
  type: typeof GET_USER_SUCCESS;
  payload: IUserData;
}

interface getUserFailAction {
  type: typeof GET_USER_FAIL;
  payload: Error;
}

interface refreshAuthTokenAction {
  type: typeof REFRESH_AUTH_TOKEN;
}

interface refreshAuthTokenSuccessAction {
  type: typeof REFRESH_AUTH_TOKEN_SUCCESS;
  payload: string;
}

interface refreshAuthTokenFailAction {
  type: typeof REFRESH_AUTH_TOKEN_FAIL;
  payload: Error;
}

export type UserActionTypes =
  | getUserAction
  | getUserSuccessAction
  | getUserFailAction
  | refreshAuthTokenAction
  | refreshAuthTokenSuccessAction
  | refreshAuthTokenFailAction;
