import { Button, CardActionArea, Grid, IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import GroupIcon from "@material-ui/icons/Group";
import MailIcon from "@material-ui/icons/MailOutline";
import { Link as RouterLink } from "@reach/router";
import React from "react";
import { useDispatch } from "react-redux";

import { IProductData } from "../../store/products/types";
import { openMessenger } from "../../store/trips/actions";
import { ITripData } from "../../store/trips/types";
import TripCardContent from "./TripCardContent";
import { csvBookingReportURL, printableBookingReportURL } from "./urls";

export type Props = {
  tripData: ITripData;
  product?: IProductData;
  linkToDetail?: boolean;
  hideActions?: boolean;
};

const TripCard = ({
  tripData,
  product = { id: 0, name: "", color: "" },
  linkToDetail,
  hideActions,
}: Props) => {
  const dispatch = useDispatch();
  const disableActions = tripData.booked_pax === 0;

  const cardContent = (
    <CardContent>
      <TripCardContent tripData={tripData} product={product} />
    </CardContent>
  );

  return (
    <Card variant="outlined">
      {linkToDetail ? (
        <CardActionArea component={RouterLink} to={`/trips/${tripData.id}`}>
          {cardContent}
        </CardActionArea>
      ) : (
        cardContent
      )}
      {!hideActions && (
        <CardActions>
          <Grid container alignItems="center" alignContent="space-between">
            <Grid item xs>
              <IconButton
                title="Message all"
                disabled={disableActions}
                onClick={() => {
                  dispatch(openMessenger(tripData, product));
                }}
              >
                <MailIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                title="View and print passenger information"
                disabled={disableActions}
                target="printable_booking_report"
                href={printableBookingReportURL(tripData)}
              >
                <GroupIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                title="CSV booking report"
                disabled={disableActions}
                target="_blank"
                href={csvBookingReportURL(tripData)}
              >
                <CloudDownloadIcon fontSize="inherit" />
              </IconButton>
            </Grid>
            {linkToDetail && (
              <Grid item>
                <Button
                  variant="text"
                  component={RouterLink}
                  endIcon={<ArrowForwardIcon />}
                  to={`/trips/${tripData.id}`}
                >
                  Detail
                </Button>
              </Grid>
            )}
          </Grid>
        </CardActions>
      )}
    </Card>
  );
};

export default TripCard;
