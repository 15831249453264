import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  UserActionTypes,
  IUserState,
  REFRESH_AUTH_TOKEN_FAIL,
  REFRESH_AUTH_TOKEN_SUCCESS,
  IUserData
} from "./types";

const initialState: IUserState = {
  user: undefined,
  fetchingUser: true
};

export function userReducer(
  state = initialState,
  action: UserActionTypes
): IUserState {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        fetchingUser: true
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        fetchingUser: false
      };
    case GET_USER_FAIL:
      return {
        ...state,
        fetchingUser: false,
        fetchingUserError: action.payload
      };
    case REFRESH_AUTH_TOKEN_FAIL:
      return {
        user: undefined,
        fetchingUser: false
      };
    case REFRESH_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          jwt: action.payload
        } as IUserData
      };
    default:
      return state;
  }
}
