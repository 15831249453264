import React from "react";
import {
  Card,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Typography,
  TableHead,
} from "@material-ui/core";
import { IQuoteData } from "../../store/enquiries/types";

type Props = {
  quoteData: IQuoteData;
};

const MoneyRow = ({
  description,
  amt,
  currencyCode,
  totalRow,
}: {
  description: string;
  amt: string;
  currencyCode: string;
  totalRow?: boolean;
}) => (
  <TableRow>
    <TableCell>
      <Typography
        variant={totalRow ? "h6" : "body1"}
        color={totalRow ? "textPrimary" : "textSecondary"}
      >
        {description}
      </Typography>
    </TableCell>
    <TableCell align="right">
      <Typography
        variant={totalRow ? "h6" : "body1"}
        color={totalRow ? "textPrimary" : "textSecondary"}
      >
        {new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: currencyCode,
        }).format(parseFloat(amt))}
      </Typography>
    </TableCell>
  </TableRow>
);

const Quote = ({ quoteData }: Props) => {
  return (
    <>
      <Typography variant="h4" gutterBottom>
        Quote
      </Typography>
      <Card style={{ marginBottom: 16 }}>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quoteData.quote_items.map((item) => (
              <MoneyRow
                currencyCode={quoteData.currency_code}
                description={`${item.description} x ${item.quantity}`}
                amt={item.total_price.toString()}
              />
            ))}

            <MoneyRow
              currencyCode={quoteData.currency_code}
              description="Total Price"
              amt={quoteData.total_price}
              totalRow
            />
          </TableBody>
        </Table>
      </Card>
      <Typography variant="h4" gutterBottom>
        Discounts &amp; Promotions
      </Typography>
      <Card style={{ marginBottom: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Discounts &amp; Reductions</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quoteData.promotion_reductions.map((item) => (
              <MoneyRow
                currencyCode={quoteData.currency_code}
                description={item.source}
                amt={`-${item.reduction_host.toString()}`}
              />
            ))}
            {quoteData.discounts.map((discount) => (
              <MoneyRow
                currencyCode={quoteData.currency_code}
                description={`${discount.discount_code__description}: ${discount.discount_code__code}`}
                amt={`-${discount.amount}`}
              />
            ))}
          </TableBody>
        </Table>
      </Card>
      <Typography variant="h4" gutterBottom>
        Payments
      </Typography>
      <Card style={{ marginBottom: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quoteData.customer_payments.map((payment) => (
              <MoneyRow
                currencyCode={quoteData.currency_code}
                description={payment.description}
                amt={payment.amount}
              />
            ))}
            <MoneyRow
              currencyCode={quoteData.currency_code}
              description="Total Due"
              amt={quoteData.grand_total}
              totalRow
            />
            <MoneyRow
              currencyCode={quoteData.currency_code}
              description="Total Paid"
              amt={quoteData.total_paid}
              totalRow
            />
            <MoneyRow
              currencyCode={quoteData.currency_code}
              description="Total Outstanding"
              amt={quoteData.total_due}
              totalRow
            />
          </TableBody>
        </Table>
      </Card>
    </>
  );
};

export default Quote;
