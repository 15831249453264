import { AppThunk } from "../types";
import {
  GET_ENQUIRIES,
  GET_ENQUIRIES_SUCCESS,
  GET_ENQUIRIES_FAIL
} from "./types";
import EnquiriesService from "./service";

export const getEnquiries = (
  tripId?: number | null,
  pageSize = 50
): AppThunk => async dispatch => {
  dispatch({ type: GET_ENQUIRIES });
  try {
    const res = await EnquiriesService.fetchEnquiries(tripId, pageSize);
    dispatch({ type: GET_ENQUIRIES_SUCCESS, payload: res });
  } catch (err) {
    dispatch({ type: GET_ENQUIRIES_FAIL, payload: err });
  }
};

export const getEnquiry = (enquiryId: number): AppThunk => async dispatch => {
  dispatch({ type: GET_ENQUIRIES });
  try {
    const res = await EnquiriesService.fetchEnquiry(enquiryId);
    dispatch({ type: GET_ENQUIRIES_SUCCESS, payload: [res] });
  } catch (err) {
    dispatch({ type: GET_ENQUIRIES_FAIL, payload: err });
  }
};
