import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@material-ui/core";
import { ArrowDropDown, Help, Menu as MenuIcon } from "@material-ui/icons";

import React from "react";
import { Link as RouterLink } from "@reach/router";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& a": {
      textDecoration: "none", // remove bootstrap link underlining
      color: "inherit", // stop it being blue
    },
  },
  appBar: {
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: theme.spacing(1),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  logoContainer: {
    [theme.breakpoints.down("xs")]: {
      width: 50,
      overflow: "hidden",
    },
  },
  logo: {
    height: 32,
    marginRight: theme.spacing(2),
  },
}));
const ManagerAppBar = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigate = (to: null | string = null) => {
    if (to) {
      navigate(to);
    }
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      color="default"
      elevation={1}
    >
      <Toolbar>
        <Box display={{ xs: "block", sm: "none" }}>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <RouterLink to="/">
          <img
            alt="Much Better Adventures"
            src="https://d9742cxh932vm.cloudfront.net/2cba190709f9f9da32ff206998669fd3/img/muchbetteradventures.svg"
            className={classes.logo}
          />
        </RouterLink>

        <Box display={{ xs: "none", sm: "block" }}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            endIcon={<ArrowDropDown />}
          >
            Menu
          </Button>
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleNavigate()}
        >
          <MenuItem component={RouterLink} to="/">
            Home
          </MenuItem>
          <MenuItem component={RouterLink} to="/enquiries">
            Bookings
          </MenuItem>
          <MenuItem component={RouterLink} to="/trips">
            Trips Calendar
          </MenuItem>
          <MenuItem
            href={`${process.env.REACT_APP_MAIN_DOMAIN}/manager/products/`}
            component="a"
          >
            Products
          </MenuItem>
          <MenuItem
            href={`${process.env.REACT_APP_MAIN_DOMAIN}/manager/companies/`}
            component="a"
          >
            Company
          </MenuItem>
          <MenuItem
            href={`${process.env.REACT_APP_MAIN_DOMAIN}/manager/booking-report/`}
            component="a"
          >
            Reports
          </MenuItem>
          <MenuItem
            href="https://help.muchbetteradventures.com/host-knowledge-base"
            component="a"
          >
            Host Knowledge Base
          </MenuItem>
        </Menu>

        <div className={classes.grow} />
        <Button
          variant="text"
          href="mailto:hello@muchbetteradventures.com?subject=Manager+Feedback"
        >
          Feedback
        </Button>
        <IconButton
          href="https://help.muchbetteradventures.com/host-knowledge-base"
          target="mba_help"
        >
          <Help />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default ManagerAppBar;
