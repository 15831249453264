import React from "react";
import { useDispatch, connect, ConnectedProps } from "react-redux";
import { getEnquiry } from "../../store/enquiries/actions";
import Quote from "./Quote";
import {
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Box,
  Grid,
  Card,
} from "@material-ui/core";
import IconCheck from "@material-ui/icons/Check";
import IconComment from "@material-ui/icons/Comment";
import IconQuestionAnswer from "@material-ui/icons/QuestionAnswer";
import IconClose from "@material-ui/icons/Close";
import { lightGreen, lightBlue, purple, red } from "@material-ui/core/colors";
import { RootState } from "../../store";

const avatarColors = {
  Booked: lightGreen[500],
  Quoted: lightBlue[500],
  Enquired: purple[500],
  Cancelled: red[500],
  Declined: red[500],
};

const avatarIcons = {
  Booked: <IconCheck fontSize="inherit" />,
  Quoted: <IconQuestionAnswer fontSize="inherit" />,
  Enquired: <IconComment fontSize="inherit" />,
  Cancelled: <IconClose fontSize="inherit" />,
  Declined: <IconClose fontSize="inherit" />,
};

const mapState = ({ enquiries }: RootState) => ({
  enquiries: enquiries.enquiries,
});

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & { enquiryIdStr: string };

const Enquiry = ({ enquiries, enquiryIdStr }: Props) => {
  const enquiryId = parseInt(enquiryIdStr, 10);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getEnquiry(enquiryId));
  }, [dispatch, enquiryId]);

  const enquiryData = enquiries.find(
    (enquiryData) => enquiryData.id === enquiryId
  );

  if (!enquiryData) {
    return <Box m={10}>Not found!</Box>;
  }

  const status = enquiryData.status.name;
  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={8} lg={6} container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <ListItem
              button
              component="a"
              target="_enquiry"
              href={`${process.env.REACT_APP_MAIN_DOMAIN}/manager/inbox/#/${enquiryData.id}`}
            >
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: avatarColors[status],
                  }}
                >
                  {avatarIcons[status]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${enquiryData.user.full_name} × ${
                  enquiryData.quote?.spaces_taken ||
                  enquiryData.number_of_adults
                }`}
                secondary={`#${enquiryData.id} · ${enquiryData.status.name} · ${enquiryData.product.title}`}
              />
            </ListItem>
          </Card>
        </Grid>
        {enquiryData.quote && (
          <Grid item xs={12}>
            <Quote quoteData={enquiryData.quote} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default connector(Enquiry);
