import React from "react";
import { Link as RouterLink } from "@reach/router";

import { IEnquiryData } from "../../store/enquiries/types";
import {
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
} from "@material-ui/core";
import IconCheck from "@material-ui/icons/Check";
import IconComment from "@material-ui/icons/Comment";
import IconQuestionAnswer from "@material-ui/icons/QuestionAnswer";
import IconClose from "@material-ui/icons/Close";
import IconStop from "@material-ui/icons/Stop";
import { lightBlue, purple, red, orange } from "@material-ui/core/colors";
interface Props {
  enquiryData: IEnquiryData;
}

const avatarColors = {
  Booked: "#98BE42",
  Quoted: lightBlue[500],
  Enquired: purple[500],
  Cancelled: red[500],
  Declined: red[500],
  Postponed: orange[500],
};

const avatarIcons = {
  Booked: <IconCheck fontSize="inherit" />,
  Quoted: <IconQuestionAnswer fontSize="inherit" />,
  Enquired: <IconComment fontSize="inherit" />,
  Cancelled: <IconClose fontSize="inherit" />,
  Declined: <IconClose fontSize="inherit" />,
  Postponed: <IconStop fontSize="inherit" />,
};

const Enquiry = ({ enquiryData }: Props) => {
  const status = enquiryData.status.name;
  return (
    <ListItem
      button
      component={RouterLink}
      target="_enquiry"
      to={`/enquiries/${enquiryData.id}`}
    >
      <ListItemAvatar>
        <Avatar
          style={{
            backgroundColor: avatarColors[status],
          }}
        >
          {avatarIcons[status]}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${enquiryData.user.full_name} × ${
          enquiryData.quote?.spaces_taken || enquiryData.number_of_adults
        }`}
        secondary={`#${enquiryData.id} · ${enquiryData.status.name} · ${enquiryData.product.title}`}
      />
    </ListItem>
  );
};

export default Enquiry;
