import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import ListComponent from "./List";

const List: React.SFC<RouteComponentProps> = (props) => {
  const { page } = props as { page: string | undefined };
  const pageInt = page !== undefined ? parseInt(page, 10) : undefined;
  return <ListComponent page={pageInt} />;
};

const PrivateGroupPricingPeriods = () => {
  return (
    <>
      <Router>
        <List path="/" />
        <List path="/page/:page" />
      </Router>
    </>
  );
};

export default PrivateGroupPricingPeriods;
