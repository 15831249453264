import {
  GET_ENQUIRIES,
  GET_ENQUIRIES_FAIL,
  GET_ENQUIRIES_SUCCESS,
  EnquiriesActionTypes,
  IEnquiryState,
  IEnquiryData
} from "./types";

import { upsert } from "../../utils";

const initialState: IEnquiryState = {
  enquiries: []
};

export function enquiriesReducer(
  state = initialState,
  action: EnquiriesActionTypes
): IEnquiryState {
  switch (action.type) {
    case GET_ENQUIRIES:
      return {
        ...state,
        fetchingEnquiries: true,
        fetchingEnquiriesError: undefined
      };
    case GET_ENQUIRIES_SUCCESS:
      const enquiries = (action.payload.reduce(upsert, [
        ...state.enquiries
      ]) as IEnquiryData[]).sort((a, b) => (a.id > b.id ? -1 : 1));
      return {
        ...state,
        enquiries,
        fetchingEnquiries: false
      };
    case GET_ENQUIRIES_FAIL:
      return {
        ...state,
        fetchingEnquiries: false,
        fetchingEnquiriesError: action.payload
      };
    default:
      return state;
  }
}
