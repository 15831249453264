import { IUserData } from "./types";
declare global {
  interface Window {
    Intercom: any;
  }
}

export const bootIntercom = (user: IUserData) => {
  window.Intercom("boot", {
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    email: user.email,
    user_id: user.id,
    user_hash: user.intercom_user_hash
  });
};
