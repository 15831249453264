import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
} from "@material-ui/core";

import { IPeriodData } from "../../store/privategrouppricingperiods/types";
import { formatDateRange, formatMoney } from "../../utils";
import { CurrencyCode } from "../../store/types";

export type Props = {
  periodData: IPeriodData;
  currencyCode: CurrencyCode;
};

const PeriodCard = ({ periodData, currencyCode = "GBP" }: Props) => (
  <Card variant="outlined">
    <CardContent>
      <Typography variant="h4" component="div" gutterBottom>
        {formatDateRange(periodData.start_date, periodData.end_date)}
        {". "}
        {periodData.duration} night{periodData.duration !== 1 && "s"}
      </Typography>
      <Typography color="textSecondary" gutterBottom>
        Based on {formatMoney(periodData.base_adult_price, currencyCode)} per
        adult
        {periodData.base_child_price && (
          <>
            {", "}
            {formatMoney(periodData.base_child_price, currencyCode)} per child
          </>
        )}
        .
      </Typography>
      {periodData.description !== "" && (
        <Typography gutterBottom>{periodData.description}</Typography>
      )}
    </CardContent>

    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>Group</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>Reduction</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>Per adult</Typography>
            </TableCell>
            {periodData.base_child_price && (
              <TableCell align="right">
                <Typography>Per child</Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {periodData.group_sizes.length === 0 ? (
            <TableRow>
              <TableCell colSpan={periodData.base_child_price ? 4 : 3}>
                <Typography color="error">
                  No group sizes have been defined for this period.
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            periodData.group_sizes.map((group, idx) => (
              <TableRow key={`${idx}`}>
                <TableCell>
                  <Typography>
                    {group.min_pax}&ndash;
                    {group.max_pax}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{group.percentage_reduction}%</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    {formatMoney(group.adult_price, currencyCode)}
                  </Typography>
                </TableCell>
                {group.child_price && (
                  <TableCell align="right">
                    <Typography>
                      {formatMoney(group.child_price, currencyCode)}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  </Card>
);

export default PeriodCard;
