import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import ListComponent from "./List";
import DetailComponent, { Props as DetailComponentProps } from "./Detail";

const List: React.SFC<RouteComponentProps> = (props) => {
  const { page } = props as { page: string | undefined };
  const pageInt = page !== undefined ? parseInt(page, 10) : undefined;
  return <ListComponent page={pageInt} />;
};

type Props = RouteComponentProps & DetailComponentProps;
const Detail: React.SFC<RouteComponentProps> = (props) => {
  const detailProps = props as DetailComponentProps;
  return <DetailComponent tripIdStr={detailProps.tripIdStr} />;
};

const Trips = () => {
  return (
    <>
      <Router>
        <List path="/" />
        <List path="/page/:page" />
        <Detail path="/:tripIdStr" />
      </Router>
    </>
  );
};

export default Trips;
