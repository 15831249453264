import { blue, purple, orange, lightGreen } from "@material-ui/core/colors";

const colorChoices: string[] = [
  blue[200],
  lightGreen[200],
  orange[200],
  purple[200],
  orange[100],
  purple[100],
  blue[100],
  lightGreen[100]
];

interface ColorLookup {
  [key: string]: string;
}
const chosenColors: ColorLookup = {};

export const getColor = (productId: number) => {
  const productIdStr: string = productId.toString();

  if (!chosenColors[productIdStr]) {
    chosenColors[productIdStr] = colorChoices.pop() || lightGreen[100];
  }
  return chosenColors[productIdStr];
};
