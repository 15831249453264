import React from "react";
import { Router, RouteComponentProps } from "@reach/router";
import ReportsComponent from "./Reports";
import { Box, Link, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const View: React.SFC<RouteComponentProps> = () => {
  return <ReportsComponent />;
};

const Reports = () => {
  return (
    <>
      <Box mb={2}>
        <Alert severity="info">
          <Typography>
            Whilst we're working on the new reports section, you can still{" "}
            <Link
              href="https://www.muchbetteradventures.com/manager/booking-report/"
              color="secondary"
            >
              access booking and pax reports here
            </Link>
            .
          </Typography>
        </Alert>
      </Box>
      <Router>
        <View path="/" />
      </Router>
    </>
  );
};

export default Reports;
