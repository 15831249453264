import {
  ReportsState,
  ReportsActionTypes,
  GET_METABASE_REPORT_SRC,
  SET_METABASE_REPORT_SRC,
  SET_METABASE_REPORT_PARAMS
} from "./types";

const initialState: ReportsState = {
  gettingMetabaseReportSrc: true,
  metabaseReportSrc: undefined,
  metabaseReportParams: { product: undefined, id: undefined, type: undefined },
  metabaseOptions: {
    "146": {
      name: "Alterations Dashboard",
      type: "dashboard"
    },
    "815": {
      name: "Changed Bookings",
      type: "question"
    }
  }
};

export function reportsReducer(
  state = initialState,
  action: ReportsActionTypes
): ReportsState {
  switch (action.type) {
    case GET_METABASE_REPORT_SRC:
      return {
        ...state,
        gettingMetabaseReportSrc: true
      };
    case SET_METABASE_REPORT_SRC:
      return {
        ...state,
        metabaseReportSrc: action.payload,
        gettingMetabaseReportSrc: false
      };
    case SET_METABASE_REPORT_PARAMS:
      return {
        ...state,
        metabaseReportParams: action.payload
      };
    default:
      return state;
  }
}

export default reportsReducer;
