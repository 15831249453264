import { AppThunk } from "../types";
import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  REFRESH_AUTH_TOKEN,
  REFRESH_AUTH_TOKEN_SUCCESS,
  REFRESH_AUTH_TOKEN_FAIL
} from "./types";
import UserService from "./service";
import { bootIntercom } from "./intercom";

const TOKEN_REFRESH_INTERVAL = 120000; // 2 minutes

export const getUser = (): AppThunk => async dispatch => {
  dispatch({ type: GET_USER });
  try {
    const user = await UserService.fetchCurrentUser();
    dispatch({ type: GET_USER_SUCCESS, payload: user });
    if (user?.jwt) {
      setTimeout(() => dispatch(refreshAuthToken()), TOKEN_REFRESH_INTERVAL);
    }
    if (user) bootIntercom(user);
  } catch (err) {
    dispatch({ type: GET_USER_FAIL, payload: err });
  }
};

export const refreshAuthToken = (): AppThunk => async (dispatch, getState) => {
  dispatch({ type: REFRESH_AUTH_TOKEN });
  const token = getState().user.user?.jwt;
  try {
    if (!token) throw new Error("JWT not set, can't refresh");
    const newToken = await UserService.refreshAuthToken(token);
    dispatch({ type: REFRESH_AUTH_TOKEN_SUCCESS, payload: newToken });
    setTimeout(() => dispatch(refreshAuthToken()), TOKEN_REFRESH_INTERVAL);
  } catch (err) {
    dispatch({ type: REFRESH_AUTH_TOKEN_FAIL, payload: err });
  }
};
