import {
  ProductsState,
  ProductsActionTypes,
  SET_PRODUCTS,
  GET_PRODUCTS
} from "./types";
import { getColor } from "./colors";

const initialState: ProductsState = {
  products: {},
  fetchingProducts: false
};

export function productsReducer(
  state = initialState,
  action: ProductsActionTypes
): ProductsState {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload.reduce(
          (obj, product) => ({
            ...obj,
            [product.id.toString()]: {
              ...product,
              color: getColor(product.id)
            }
          }),
          {}
        ),
        fetchingProducts: false
      };
    case GET_PRODUCTS:
      return {
        ...state,
        fetchingProducts: true
      };
    default:
      return state;
  }
}
