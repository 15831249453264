import { IProductData } from "../products/types";

export interface ITripData {
  spaces_left: number;
  pax_required: number;
  total_pax: number;
  duration: number;
  booked_pax: number;
  is_full: boolean;
  status: string;
  adult_net: string;
  child_net: string;
  id: number;
  uuid: string;
  product: number;
  start_date: string;
  end_date: string;
  confirmation_date: string;
  description: string;
  is_weekend: boolean;
  is_special_offer: boolean;
  is_hidden: boolean;
  is_private?: boolean;
  min_pax: number;
  max_pax: number;
  created: string;
  modified: string;
  adult_price: string;
  child_price: string;
  adult_price_was: string;
  child_price_was: string;
  offsite_booked_pax: number;
  guaranteed_at?: string;
  is_cancelled?: boolean;
  cancelled_at?: string;
  cancellation_reason?: string;
  is_exclusive: boolean;
  emergency_contact?: string;
  meeting_point?: string;
  emergency_contact_inherited?: boolean;
  meeting_point_inherited?: boolean;
}

export interface IGlobalFilter {
  label: string;
  value: string;
  on: boolean;
}

export interface TripsState {
  trips: ITripData[];
  fetchingTrips: boolean;
  filterDialogOpen: boolean;
  globalFilters: IGlobalFilter[];
  messengerIsOpen: boolean;
  messageIsSending: boolean;
  messageSent?: boolean;
  messageSendError?: Error;
  messageTrip?: ITripData;
  messageTripProduct?: IProductData;
}

export const OPEN_FILTERS = "OPEN_FILTERS";
export const CLOSE_FILTERS = "CLOSE_FILTERS";
export const OPEN_MESSENGER = "OPEN_MESSENGER";
export const CLOSE_MESSENGER = "CLOSE_MESSENGER";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL";
export const SET_GLOBAL_FILTERS = "SET_GLOBAL_FILTERS";
export const SET_TRIPS = "SET_TRIPS";
export const GET_TRIPS = "GET_TRIPS";
export const SET_EMERGENCY_CONTACT = "SET_EMERGENCY_CONTACT";
export const SET_EMERGENCY_CONTACT_SUCCESS = "SET_EMERGENCY_CONTACT_SUCCESS";
export const SET_EMERGENCY_CONTACT_FAIL = "SET_EMERGENCY_CONTACT_FAIL";
export const SET_MEETING_POINT = "SET_MEETING_POINT";
export const SET_MEETING_POINT_SUCCESS = "SET_MEETING_POINT_SUCCESS";
export const SET_MEETING_POINT_FAIL = "SET_MEETING_POINT_FAIL";

export interface IOpenFiltersAction {
  type: typeof OPEN_FILTERS;
}

export interface ICloseFiltersAction {
  type: typeof CLOSE_FILTERS;
}

export interface IOpenMessengerAction {
  type: typeof OPEN_MESSENGER;
  payload: {
    trip: ITripData;
    product: IProductData;
  };
}

export interface ICloseMessengerAction {
  type: typeof CLOSE_MESSENGER;
}

export interface ISetGlobalFiltersAction {
  type: typeof SET_GLOBAL_FILTERS;
  payload: IGlobalFilter[];
}

export interface ISetTripsAction {
  type: typeof SET_TRIPS;
  payload: ITripData[];
}
export interface IGetTripsAction {
  type: typeof GET_TRIPS;
}
export interface ISendMessageAction {
  type: typeof SEND_MESSAGE;
}
export interface ISendMessageSuccessAction {
  type: typeof SEND_MESSAGE_SUCCESS;
}
export interface ISendMessageFailAction {
  type: typeof SEND_MESSAGE_FAIL;
  payload: Error;
}

export interface ISetEmergencyContactAction {
  type: typeof SET_EMERGENCY_CONTACT;
  payload: {
    tripId: number;
    value: string;
  };
}
export interface ISetEmergencyContactSuccessAction {
  type: typeof SET_EMERGENCY_CONTACT_SUCCESS;
  payload: ITripData;
}
export interface ISetEmergencyContactFailAction {
  type: typeof SET_EMERGENCY_CONTACT_FAIL;
  payload: Error;
}

export interface ISetMeetingPointAction {
  type: typeof SET_MEETING_POINT;
  payload: {
    tripId: number;
    value: string;
  };
}
export interface ISetMeetingPointSuccessAction {
  type: typeof SET_MEETING_POINT_SUCCESS;
  payload: ITripData;
}
export interface ISetMeetingPointFailAction {
  type: typeof SET_MEETING_POINT_FAIL;
  payload: Error;
}

export type TripsActionTypes =
  | IOpenFiltersAction
  | ICloseFiltersAction
  | IOpenMessengerAction
  | ICloseMessengerAction
  | ISetGlobalFiltersAction
  | IGetTripsAction
  | ISetTripsAction
  | ISendMessageAction
  | ISendMessageSuccessAction
  | ISendMessageFailAction
  | ISetEmergencyContactAction
  | ISetEmergencyContactSuccessAction
  | ISetEmergencyContactFailAction
  | ISetMeetingPointAction
  | ISetMeetingPointSuccessAction
  | ISetMeetingPointFailAction;
