import {
  Box,
  Card,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";
import {
  csvBookingReportURL,
  csvPassengerReportURL,
  printableBookingReportURL,
} from "./urls";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EditableField from "./EditableField";
import Enquiry from "../Enquiries/EnquiryListItem";
import GroupIcon from "@material-ui/icons/Group";
import MailIcon from "@material-ui/icons/MailOutline";
import Messenger from "./Messenger";
import React from "react";
import { RootState } from "../../store";
import TripCard from "./TripCard";
import { getEnquiries } from "../../store/enquiries/actions";
import { getProducts } from "../../store/products/actions";
import { getTrip } from "../../store/trips/actions";
import { openMessenger } from "../../store/trips/actions";

const mapState = ({ trips }: RootState) => ({
  trips: trips.trips,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
export type Props = PropsFromRedux & { tripIdStr: string };

const Detail = ({ tripIdStr, trips }: Props) => {
  const dispatch = useDispatch();
  const tripId = parseInt(tripIdStr, 10);

  React.useEffect(() => {
    dispatch(getTrip(tripId));
    dispatch(getProducts());
    dispatch(getEnquiries(tripId));
  }, [dispatch, tripId]);

  const tripData = trips.find((tripData) => tripData.id === tripId);
  const product = useSelector((state: RootState) =>
    tripData
      ? state.products.products[tripData.product.toString()]
      : { id: 0, name: "", color: "" }
  );

  const enquiries = useSelector((state: RootState) =>
    state.enquiries.enquiries.filter(
      (enquiry) => enquiry.quote?.social_trip === tripData?.id
    )
  );
  const fetchingEnquiries = useSelector(
    (state: RootState) => state.enquiries.fetchingEnquiries
  );

  if (!tripData) {
    return <Typography>Loading trip data...</Typography>;
  }

  return (
    <>
      <Messenger />

      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={6} md={5}>
          <Box mb={4} position="relative">
            <IconButton
              style={{ position: "absolute", left: -64, top: -4 }}
              onClick={() => window.history.back()}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" gutterBottom>
              The Trip
            </Typography>
            <TripCard tripData={tripData} product={product} hideActions />
          </Box>
          <Box mb={4}>
            <Typography variant="h5">Tools</Typography>
            <Typography gutterBottom color="textSecondary">
              Message all bookers, and view or download booking reports.
            </Typography>
            <Card variant="outlined">
              <List>
                <ListItem
                  button
                  onClick={() => dispatch(openMessenger(tripData, product))}
                >
                  <ListItemIcon>
                    <MailIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Message All"
                    secondary="Send a message to all 'Booked' enquiries"
                  />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  target="_blank"
                  href={printableBookingReportURL(tripData)}
                >
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Passenger Info"
                    secondary="View all passenger info"
                  />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  target="_blank"
                  href={csvPassengerReportURL(tripData)}
                >
                  <ListItemIcon>
                    <CloudDownloadIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Passenger CSV"
                    secondary="Download the passenger info CSV file"
                  />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  target="_blank"
                  href={csvBookingReportURL(tripData)}
                >
                  <ListItemIcon>
                    <CloudDownloadIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Booking CSV"
                    secondary="Download the booking CSV file"
                  />
                </ListItem>
              </List>
            </Card>
          </Box>
          <Box mb={4}>
            <Typography variant="h5">Customer Instructions</Typography>
            <Typography gutterBottom color="textSecondary">
              Instructions default to the product settings. Any changes made
              here will apply for this trip only.
            </Typography>
            <Card variant="outlined">
              <Box p={2}>
                <Grid container spacing={2}>
                  <EditableField
                    tripId={tripId}
                    label="Meeting Point"
                    value={tripData.meeting_point}
                    fieldName="meeting_point"
                    isInherited={tripData.meeting_point_inherited}
                  />
                  <EditableField
                    tripId={tripId}
                    label="Emergency Contact"
                    value={tripData.emergency_contact}
                    fieldName="emergency_contact"
                    isInherited={tripData.emergency_contact_inherited}
                  />
                </Grid>
              </Box>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Box mb={4}>
            <Typography variant="h4" gutterBottom>
              {enquiries.length}{" "}
              {enquiries.length === 1 ? "Enquiry" : "Enquiries"}
            </Typography>
            <Card variant="outlined">
              <List>
                {enquiries.length > 0 ? (
                  enquiries.map((enquiryData) => (
                    <React.Fragment key={enquiryData.id}>
                      <Enquiry enquiryData={enquiryData} />
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText
                      primary={
                        fetchingEnquiries ? "Loading..." : "No enquiries found"
                      }
                    />
                  </ListItem>
                )}
              </List>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default connector(Detail);
