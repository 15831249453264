import { AppThunk } from "../types";
import TripsService from "./service";

import {
  OPEN_FILTERS,
  CLOSE_FILTERS,
  OPEN_MESSENGER,
  CLOSE_MESSENGER,
  SET_GLOBAL_FILTERS,
  GET_TRIPS,
  SET_TRIPS,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAIL,
  SET_EMERGENCY_CONTACT,
  SET_EMERGENCY_CONTACT_SUCCESS,
  SET_EMERGENCY_CONTACT_FAIL,
  SET_MEETING_POINT,
  SET_MEETING_POINT_SUCCESS,
  SET_MEETING_POINT_FAIL,
  TripsActionTypes,
  IGlobalFilter,
  ITripData
} from "./types";

import { IProductData } from "../products/types";

export function openFilters(): TripsActionTypes {
  return {
    type: OPEN_FILTERS
  };
}
export function closeFilters(): TripsActionTypes {
  return {
    type: CLOSE_FILTERS
  };
}

export function openMessenger(
  trip: ITripData,
  product: IProductData
): TripsActionTypes {
  return {
    type: OPEN_MESSENGER,
    payload: {
      trip,
      product
    }
  };
}
export function closeMessenger(): TripsActionTypes {
  return {
    type: CLOSE_MESSENGER
  };
}

export function setTrips(trips: ITripData[]): TripsActionTypes {
  return {
    type: SET_TRIPS,
    payload: trips
  };
}

export function setGlobalFilters(
  nextGlobalFilters: IGlobalFilter[]
): TripsActionTypes {
  return {
    type: SET_GLOBAL_FILTERS,
    payload: nextGlobalFilters
  };
}

export const getTrips = (pageSize?: number): AppThunk => async dispatch => {
  dispatch({ type: GET_TRIPS });
  const asyncResp = await TripsService.fetchTrips(pageSize);
  dispatch(setTrips(asyncResp));
};

export const getTrip = (tripId: number): AppThunk => async dispatch => {
  dispatch({ type: GET_TRIPS });
  const asyncResp = await TripsService.fetchTrip(tripId);
  dispatch(setTrips([asyncResp]));
};

export const sendMessage = (
  message: string,
  tripId: number
): AppThunk => async dispatch => {
  dispatch({ type: SEND_MESSAGE });
  try {
    await TripsService.sendMessage(message, tripId);
    dispatch({ type: SEND_MESSAGE_SUCCESS });
  } catch (err) {
    dispatch({ type: SEND_MESSAGE_FAIL, payload: err });
  }
};

export const setEmergencyContact = (
  value: string,
  tripId: number
): AppThunk => async dispatch => {
  dispatch({ type: SET_EMERGENCY_CONTACT, payload: { value, tripId } });
  try {
    const tripData = await TripsService.setEmergencyContact(value, tripId);
    dispatch({ type: SET_EMERGENCY_CONTACT_SUCCESS, payload: tripData });
  } catch (err) {
    console.log(err);
    dispatch({ type: SET_EMERGENCY_CONTACT_FAIL, payload: err });
  }
};

export const setMeetingPoint = (
  value: string,
  tripId: number
): AppThunk => async dispatch => {
  dispatch({ type: SET_MEETING_POINT, payload: { value, tripId } });
  try {
    const tripData = await TripsService.setMeetingPoint(value, tripId);
    dispatch({ type: SET_MEETING_POINT_SUCCESS, payload: tripData });
  } catch (err) {
    console.log(err);
    dispatch({ type: SET_MEETING_POINT_FAIL, payload: err });
  }
};
